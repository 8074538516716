/// <reference path="o365.pwa.declaration.sw.plugins.AppCacheKey.d.ts" />

import type { IO365ServiceWorkerGlobalScope } from 'o365.pwa.declaration.sw.O365ServiceWorkerGlobalScope.d.ts';
import type { CacheKeyWillBeUsedCallbackParam } from 'o365.pwa.declaration.sw.workbox.d.ts';
import type { Request } from 'o365.pwa.declaration.sw.ServiceWorkerGlobalScope.d.ts';

declare var self: IO365ServiceWorkerGlobalScope;

(() => {
    class AppCacheKey {
        public async cacheKeyWillBeUsed({ request }: CacheKeyWillBeUsedCallbackParam): Promise<string | Request> {
            const newUrl = new URL(request.url);

            newUrl.search = '';
            newUrl.hash = '';
            newUrl.pathname = `/nt/offline/${newUrl.pathname.split('/').pop()}` ?? ''; // Get app id without parent path

            return newUrl.href;
        }
    }
    
    self.o365.exportScripts<typeof import('o365.pwa.declaration.sw.plugins.AppCacheKey.d.ts')>({ AppCacheKey });
})();
